import { useState, useEffect } from "react";
import "./dashboard.scss";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import Completed from "./Completed";

export default function History(props) {
  const [data, setData] = useState(props.data);
  const [searchData, setSearchData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [searchRepair, setSearchRepair] = useState("");
  const [parts, setParts] = useState(0);
  const [arranged, setArranged] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [sorted,setSorted] =useState(false)
  const [openComp,setOpenComp]=useState(false)


  useEffect(() => {
    setData(props.data)
    var waiting = props.data.filter((i) => i.status === "Waiting On Parts")
    setParts(waiting.length)
    var repair = props.data.filter((i) => i.status === "Repair Arranged")
    setArranged(repair.length);
  }, [props]);

  function Search() {
    axios
      .get(
        `https://nitropos-backend.nitrosolutions.co.uk/maintenance/search/?search=${searchRepair}`
      )
      .then((res) => {
        setSearchData(res.data);
        if (res.data.length != 0){
            setShowResults(true);
        } else {
            setAlert(true);
            setTimeout(() => {
              setAlert(false);
            }, 2500);  
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleKeyDown(e){
    if (e.keyCode === 13){
        Search()
    }
  }

  const sortSite = () => {
    setSorted(!sorted)
    var siteData =  data.sort((a,b)=>a.site.localeCompare(b.site))
    setData(siteData)
  }

  const sortDate = () => {
    setSorted(!sorted)
    var dateData = data.sort((a,b)=>new Date(a.date)-new Date(b.date))
    setData(dateData)
  }

  const sortStatus = () => {
    setSorted(!sorted)
    var statusData = data.sort((a,b)=>a.status.localeCompare(b.status))
    setData(statusData)
  }

  const closeCompleted = ()=>{
    setOpenComp(!openComp)
  }


  return (
    <aside className="dash__history">
      {alert ? (
        <Alert
          severity="warning"
          sx={{ position: "absolute", top: "8vh", left: "3vw", width: "28vw" }}
        >
          No matching Repairs
        </Alert>
      ) : (
        <></>
      )}
      <label>Search for Repair</label>
      <div className="dash__search">
        <input
          type="text"
          className="dash__search"
          style={{ width: "100%" }}
          onChange={(e) => setSearchRepair(e.target.value)}
          focus
          onKeyDown={handleKeyDown}
        />
        <IconButton onClick={() => Search()}>
          <SearchIcon fontSize="large" sx={{ color: "#03318C" }} />
        </IconButton>
        <IconButton onClick={() => setShowResults(false)}>
            <CloseIcon fontSize="medium" sx={{color: "#03318C"}} />
        </IconButton>
      </div>
      {showResults ? (
        <div className="search__result">
          <table>
            <thead>
              <tr>
                <th>Site</th>
                <th>Date</th>
                <th>Machine Make</th>
              </tr>
            </thead>
            <tbody>
              {searchData.map(function (d, i) {
                return(
                <tr onClick={()=> props.handleRepair(d)}>
                  <th>{d.site}</th>
                  <th>{d.date}</th>
                  <th>{d.machineMake}</th>
                </tr>)
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
      <div className="dash__repairs">
        <div className="repair__card">
            <h4>Open</h4>
            <h5>{data.length}</h5>
        </div>
        <div className="repair__card">
            <h4>Awaiting Parts</h4>
            <h5>{parts}</h5>
        </div>
        <div className="repair__card">
            <h4>Repair Arranged</h4>
            <h5>{arranged}</h5>
        </div>
      </div>
      <div className="dash__open">
        <table className="repair">
          <thead>
            <tr style={{cursor: "pointer"}}>
              <th onClick={()=> sortSite()}>Site</th>
              <th onClick={()=> sortDate()}>Date</th>
              <th onClick={()=> sortStatus()}>Machine Make</th>
            </tr>
          </thead>
          <tbody>
            {data.map(function (d, i) {
              return (
                <tr onClick={()=> props.handleRepair(d)}>
                  <th>{d.site}</th>
                  <th>{d.date}</th>
                  <th>{d.machineMake}</th>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <button onClick={()=>{setOpenComp(!openComp)}} style={{textAlign:"center",position:"absolute",left:"14%",bottom:"5%",padding:"10px",borderRadius:"5px",backgroundColor:"white",colour:"dodgerblue",borderColor:"dodgerblue"}}>COMPLETED</button>
      <Completed show={openComp} close={closeCompleted}/>
    </aside>
  );
}
