import { useState, useEffect } from 'react'
import axios from 'axios'
import IndvLaundry from './IndvLaundry'

export default function Laundry() {

    const [active, setActive] = useState([])
    const [complete, setComplete] = useState([])
    const [showActive, setShowActive] = useState(false)
    const [showComplete, setShowComplete] = useState(false)
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        axios.get("https://nitrous.rt-itservices.co.uk/racetrack/laundry/active").then((res) => {
            console.log(res.data)
            setActive(res.data)
        }).catch((err) => {
            console.log(err)
        })
        axios.get("https://nitrous.rt-itservices.co.uk/racetrack/laundry/checked").then((res) => {
            setComplete(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [update])

    const check = (id, status) => {
        axios.post("https://nitrous.rt-itservices.co.uk/racetrack/laundry/complete", { id: id, status: !status }).then((res) => {
            setUpdate(!update)
        }).catch((err) => {
            console.log(err)
        })
    }

    const checkCctv = (id, status) => {
        axios.post("https://nitrous.rt-itservices.co.uk/racetrack/laundry/cctv/complete", { id: id, status: !status }).then((res) => {
            setUpdate(!update)
        }).catch((err) => {
            console.log(err)
        })
    }

    const refundStatus = (id, refund) => {
        axios.post("https://nitrous.rt-itservices.co.uk/racetrack/laundry/refund", { id: id, status: refund }).then((res) => {
            setUpdate(!update)
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <div style={{ textAlign: "center" }}>
            <button style={{ width: "75%", border: "1px solid black", borderRadius: "10px", backgroundColor: "#be1a1a", color: "white", fontSize: "1.5em" }} onClick={() => { setShowActive(!showActive) }}>Active Submissions - {active.length}</button>
            {showActive ?
                <>
                    <hr />
                    <table style={{ textAlign: "center", border: "1px solid black" }}>
                        <tr style={{ fontWeight: "bold", border: "1px solid black", backgroundColor: "silver" }}>
                            <td style={{ border: "1px solid black" }}>Name</td>
                            <td style={{ border: "1px solid black" }}>Email</td>
                            <td style={{ border: "1px solid black" }}>Site</td>
                            <td style={{ border: "1px solid black" }}>Date</td>
                            <td style={{ border: "1px solid black" }}>Machine</td>
                            <td style={{ border: "1px solid black" }}>Issue</td>
                            <td style={{ border: "1px solid black" }}>Payment Type</td>
                            <td style={{ border: "1px solid black" }}>Amount</td>
                            <td style={{ border: "1px solid black" }}>Form Submission</td>
                            <td style={{ border: "1px solid black" }}>Checked</td>
                            <td style={{ border: "1px solid black" }}>CCTV Checked</td>
                            <td style={{ border: "1px solid black" }}>Refund Required</td>
                            <td  style={{ border: "1px solid black" }}>Account</td>
                        </tr>
                        {active.map(function (d, i) {
                            return (
                                <IndvLaundry d={d} check={check} checkCctv={checkCctv} refundStatus={refundStatus}/>
                            )
                        })}
                    </table>
                </>
                :
                <></>
            }
            <hr />
            <button style={{ width: "75%", border: "1px solid black", borderRadius: "10px", backgroundColor: "#be1a1a", color: "white", fontSize: "1.5em" }} onClick={() => { setShowComplete(!showComplete) }}>Checked Submissions - {complete.length}</button>
            {showComplete ?
                <>
                    <hr />
                    <table style={{ textAlign: "center", border: "1px solid black" }}>
                        <tr style={{ fontWeight: "bold", border: "1px solid black", backgroundColor: "silver" }}>
                            <td style={{ border: "1px solid black" }}>Name</td>
                            <td style={{ border: "1px solid black" }}>Email</td>
                            <td style={{ border: "1px solid black" }}>Site</td>
                            <td style={{ border: "1px solid black" }}>Date</td>
                            <td style={{ border: "1px solid black" }}>Machine</td>
                            <td style={{ border: "1px solid black" }}>Issue</td>
                            <td style={{ border: "1px solid black" }}>Payment Type</td>
                            <td style={{ border: "1px solid black" }}>Amount</td>
                            <td style={{ border: "1px solid black" }}>Form Submission</td>
                            <td style={{ border: "1px solid black" }}>Checked</td>
                            <td style={{ border: "1px solid black" }}>CCTV Checked</td>
                            <td style={{ border: "1px solid black" }}>Refund Required</td>
                        </tr>
                        {complete.map(function (d, i) {
                            return (
                                <tr key={i}>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>{d.name}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>{d.email}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>{d.site}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>{d.date}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>{d.machine}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>{d.issue}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>{d.payment}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>£{d.amount}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>{d.formSubmitted}</td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}><input type="radio" checked={d.checked} onClick={() => { check(d._id, d.checked) }} /></td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}><input type="radio" checked={d.cctvChecked} onClick={() => { checkCctv(d._id, d.cctvChecked) }} /></td>
                                    <td style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>
                                        {d.refundRequired?
                                        <h2>✔</h2>
                                        :
                                        d.refundRequired==false?
                                        <h2>❌</h2>
                                        :
                                        <h2>❔</h2>}
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                </>
                :
                <></>
            }
        </div>
    )
}