import { useState, useEffect } from "react";
import "./dashboard.scss";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import axios from "axios";
import HideImageIcon from "@mui/icons-material/HideImage";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25vw",
  height: "38vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};

const imgStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "80vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};

export default function Details(props) {
  const [repair, setRepair] = useState({});
  const [reload, setReload] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [notes, setNotes] = useState([]);
  const [audit, setAudit] = useState([]);
  const [user, setUser] = useState("");
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openNotes, setOpenNotes] = useState(false);
  const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => setOpenNotes(false);
  const [note, setNote] = useState("");
  const [openImg, setOpenImg] = useState(false);
  const handleOpenImg = () => setOpenImg(true);
  const handleCloseImg = () => setOpenImg(false);

  useEffect(() => {
    let staff = sessionStorage.getItem("name");
    setUser(staff);
    if (props.repair) {
      setRepair(props.repair);
      setNotes(props.repair.notes);
      setAudit(props.repair.statusLog);
    }
    console.log(props.repair);
  }, [props, reload]);

  const statusUpdate = async () => {
    var data = {
      id: repair.issueID,
      staffName: user,
      status: status,
    };
    document.getElementById("hideButton").style.display = "none";
    await axios
      .post(
        "https://nitropos-backend.nitrosolutions.co.uk/maintenance/update",
        data
      )
      .then((res) => {
        rel();
        handleClose();
        setSuccessAlert(true);
        setTimeout(() => {
          setSuccessAlert(false);
          setReload(!reload);
        });
      })
      .catch((err) => {
        console.log(err);
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        });
        document.getElementById("hideButton").style.display = "block";
      });
  };

  const rel = () => {
    setReload(!reload);
  };

  const addNote = async () => {
    var noteData = { id: repair.issueID, note: note, staffName: user };
    document.getElementById("hideNoteButton").style.display = "none";
    await axios
      .post(
        "https://nitropos-backend.nitrosolutions.co.uk/maintenance/addNote",
        noteData
      )
      .then((res) => {
        rel();
        setReload(!reload);
        handleCloseNotes();
        setSuccessAlert(true);
        setTimeout(() => {
          setSuccessAlert(false);
        });
      })
      .catch((err) => {
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        });
        document.getElementById("hideNoteButton").style.display = "block";
      });
  };

  return (
    <>
      {repair ? (
        <section className="dash__details">
          {successAlert ? (
            <Alert
              variant="filled"
              severity="success"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Repair successfully updated
            </Alert>
          ) : null}
          {errorAlert ? (
            <Alert
              variant="filled"
              severity="error"
              sx={{
                position: "absolute",
                top: "8vh",
                width: "80vw",
                left: "10vw",
              }}
            >
              Repair could not be updated, please try again
            </Alert>
          ) : (
            <></>
          )}
          <div className="status__updates">
            <table>
              <thead>
                <tr>
                  <th>Repair ID:</th>
                  <th>Submited on:</th>
                  <th>Last Updated on:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{repair.issueID}</th>
                  <th>
                    {new Date(repair.submissionDate).toLocaleDateString()}
                  </th>
                  <th>{new Date(repair.lastUpdate).toLocaleDateString()}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="reprair__cards">
            <div className="repair__column">
              <div className="card">
                <h3>Issue: </h3>
                <p>
                  Issue Type: <span>{repair.issue}</span>
                </p>
                <p>
                  Machine Make: <span>{repair.machineMake}</span>
                </p>
                <p>
                  Description: <span>{repair.description}</span>
                </p>
                <p>
                  Serial Number: <span>{repair.serialNumber}</span>
                </p>
              </div>
              <div className="card">
                <h3>Audit Log</h3>
                <table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Update Date</th>
                      <th>Staff Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {audit.map(function (d, i) {
                      return (
                        <tr>
                          <th>{d.newStatus}</th>
                          <th>{new Date(d.updateDate).toLocaleString()}</th>
                          <th>{d.updatedBy}</th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="card">
                <Button
                  variant="outlined"
                  startIcon={<SettingsIcon />}
                  onClick={handleOpen}
                  sx={{ alignSelf: "center", margin: "2%" }}
                >
                  Change Status
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      <h3>Change Repair Status</h3>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <label>Current Status</label>
                      <br />
                      <b style={{ textDecoration: "underline" }}>
                        {repair.status}
                      </b>
                      <br />
                      <label>New Status</label>
                      <br />
                      <input
                        name="radStatus"
                        type="radio"
                        value="Investigation Started"
                        onChange={(e) => setStatus(e.target.value)}
                      />
                      <label>Investigation Started</label>
                      <br />
                      <input
                        name="radStatus"
                        type="radio"
                        value="Repair Arranged"
                        onChange={(e) => setStatus(e.target.value)}
                      />
                      <label>Repair Arranged</label>
                      <br />
                      <input
                        name="radStatus"
                        type="radio"
                        value="Waiting On Parts"
                        onChange={(e) => setStatus(e.target.value)}
                      />
                      <label>Waiting On Parts</label>
                      <br />
                      <input
                        name="radStatus"
                        type="radio"
                        value="Parts Arrived"
                        onChange={(e) => setStatus(e.target.value)}
                      />
                      <label>Parts Arrived</label>
                      <br />
                      <input
                        name="radStatus"
                        type="radio"
                        value="Repair Complete"
                        onChange={(e) => setStatus(e.target.value)}
                      />
                      <label>Repair Complete</label>
                      <br />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => statusUpdate()}
                        className="button"
                        id="hideButton"
                        sx={{ margin: "2% auto" }}
                      >
                        Send
                      </Button>
                    </Typography>
                  </Box>
                </Modal>
                <Button
                  variant="outlined"
                  startIcon={<TextSnippetIcon />}
                  onClick={handleOpenNotes}
                  sx={{ alignSelf: "center", margin: "2%" }}
                >
                  Add Note
                </Button>
                <Modal
                  open={openNotes}
                  onClose={handleCloseNotes}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      <h3>Add Note</h3>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <label>Note: </label>
                      <input
                        style={{
                          width: "100%",
                          padding: "5px",
                          margin: "10px",
                        }}
                        type="text"
                        placeholder="Enter Note..."
                        onChange={(e) => setNote(e.target.value)}
                        required
                      />
                      <br />
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => addNote()}
                        className="button"
                        id="hideNoteButton"
                        sx={{ margin: "2% auto" }}
                      >
                        Send
                      </Button>
                    </Typography>
                  </Box>
                </Modal>
                {repair.picture ? (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<ImageSearchIcon />}
                      onClick={handleOpenImg}
                      sx={{ alignSelf: "center", margin: "2%" }}
                    >
                      See image
                    </Button>
                    <Modal
                      open={openImg}
                      onClose={handleCloseImg}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={imgStyle}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          <h3>Image</h3>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <img
                            src={repair.picture}
                            alt="repair image"
                            width="60%"
                          />
                        </Typography>
                      </Box>
                    </Modal>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<HideImageIcon />}
                    sx={{ color: "#333", alignSelf: "center", margin: "2%" }}
                  >
                    No Image
                  </Button>
                )}
              </div>
            </div>
            <div className="audit__log">
              <h3>Notes:</h3>
              {notes ? (
                <table>
                  <thead>
                    <tr>
                      <th>Staff</th>
                      <th>Note</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notes.map(function (d, i) {
                      return (
                        <tr>
                          <th>{d.addedBy}</th>
                          <th>{d.note}</th>
                          <th>{new Date(d.addedDate).toLocaleString()}</th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p>No Notes to Show</p>
              )}
            </div>
          </div>
        </section>
      ) : (
        <section className="dash__details">
          <div className="details__id">
            <h4>Repair ID:</h4>
            <h4>----</h4>
          </div>
          <div className="status__updates">
            <table className="status">
              <thead>
                <tr>
                  <th>Submited on:</th>
                  <th>Last Updated on:</th>
                </tr>
              </thead>
              <tr>
                <th>----</th>
                <th>----</th>
              </tr>
            </table>
          </div>
          <div className="toolbar">
            <Button variant="outlined" startIcon={<SettingsIcon />}>
              Change Status
            </Button>
            <Button variant="outlined" startIcon={<TextSnippetIcon />}>
              Add Note
            </Button>
          </div>
          <div className="reprair__cards">
            <div className="card">
              <h3>Isssue: </h3>
              <p>
                Issue Type: <span>----</span>
              </p>
              <p>
                Machine Make: <span>----</span>
              </p>
              <p>
                Machine Model: <span>----</span>
              </p>
              <p>
                Machine Number: <span>----</span>
              </p>
            </div>
            <div className="card">
              <h3>Notes: </h3>
              <p>----</p>
            </div>
            <div className="card">
              <h3>Description: </h3>
              <p>----</p>
            </div>
          </div>
          <div className="audit__log">
            <h3>Audit Log</h3>
            <table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Update Date</th>
                  <th>Staff Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>----</th>
                  <th>----</th>
                  <th>----</th>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      )}
    </>
  );
}
