import { useState, useEffect } from "react";
import axios from "axios";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  height: "52vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function IndvLaundry(props) {
  const [d, setD] = useState(props.d);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [update, setUpdate] = useState(false);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    axios
      .get(
        `https://nitrous.rt-itservices.co.uk/racetrack/laundry/getNotes?name=${props.d.name}`
      )
      .then((res) => {
        // console.log(res.data.notes)
        if (res.data.notes) {
          setNotes(res.data.notes);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const addNote = async () => {
    if (note == "" || name == "") {
      alert("All Fields Must Be Entered!");
    } else {
      setShowButton(false);
      var noteData = { id: d.name, note: note, staffName: name };
      await axios
        .post(
          "https://nitrous.rt-itservices.co.uk/racetrack/laundry/addNote",
          noteData
        )
        .then((res) => {
          alert("A Note Has Been Added To This Issue!");
          setUpdate(!update);
          setShowButton(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <tr>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.name}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.email}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.site}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.date}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.machine}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.issue}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.payment}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        £{d.amount}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.formSubmitted}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        <input
          type="radio"
          checked={d.checked}
          onClick={() => {
            props.check(d._id, d.checked);
          }}
        />
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        <input
          type="radio"
          checked={d.cctvChecked}
          onClick={() => {
            props.checkCctv(d._id, d.checkCctv);
          }}
        />
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        {d.refundRequired ? (
          <h2>✔</h2>
        ) : (
          <>
            <button
              onClick={() => {
                props.refundStatus(d._id, true);
              }}
            >
              ✔
            </button>
            <button
              onClick={() => {
                props.refundStatus(d._id, false);
              }}
            >
              ❌
            </button>
          </>
        )}
      </td>
      <td
        style={{
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        <Tooltip title="See Notes">
          <IconButton onClick={handleOpen}>
            {notes.length === 0 ? (
              <div>
                <SpeakerNotesOffIcon />
              </div>
            ) : (
              <div id="notesIcon">
                <SpeakerNotesIcon />
              </div>
            )}
          </IconButton>
        </Tooltip>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          sx={{ overflowY: "scroll" }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                <u>Account Number for Cash Transactions</u>
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                {notes.length > 0 ? (
                  <table>
                    <tr style={{ backgroundColor: "#be1a1a", color: "white" }}>
                      <th>Note</th>
                      <th>Added By</th>
                      <th>Date</th>
                    </tr>
                    {notes.map(function (d, i) {
                      return (
                        <tr style={{ border: "1px solid black" }}>
                          <td style={{ border: "1px solid black" }}>
                            {d.note}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {d.addedBy}
                          </td>
                          <td style={{ border: "1px solid black" }}>
                            {new Date(d.addedDate).toUTCString()}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                ) : (
                  <p>No Notes Have Been Added</p>
                )}
                <hr />
                <div>
                  <h4>
                    <u>Add Note</u>
                  </h4>
                  <label>Note: </label>
                  <input
                    style={{
                      width: "100%",
                      padding: "5px",
                      margin: "10px",
                    }}
                    type="text"
                    placeholder="Enter Note..."
                    onChange={(e) => setNote(e.target.value)}
                    required
                  />
                  <br />
                  <label>Your Name: </label>
                  <input
                    style={{
                      width: "100%",
                      padding: "5px",
                      margin: "10px",
                    }}
                    type="text"
                    placeholder="Enter Your Name..."
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  {showButton ? (
                    <button
                      style={{
                        width: "100%",
                        padding: "5px",
                        margin: "10px",
                        backgroundColor: "#be1a1a",
                        color: "white",
                      }}
                      onClick={() => addNote()}
                    >
                      Add Note
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </Typography>
            </Box>
          </Fade>
        </Modal>
      </td>
    </tr>
  );
}
