// import "../App.scss";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import Alert from "@mui/material/Alert";

export default function Form() {
  const [site, setSite] = useState("");
  const [name, setName] = useState("");
  const [machine, setMachine] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [issue, setIssue] = useState("");
  const [description, setDescription] = useState("");
  const [logComment, setLogComment] = useState("");
  const [image, setImage] = useState("");
  const [date, setDate] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [check, setCheck] = useState(false);
  const [logDate, setLogDate] = useState("");
  const [message, setMessage] = useState("");
  const [showSendButton, setShowSendButton] = useState(true);
  const [sites, setSites] = useState([]);
  const [completionAlert, setCompletionAlert] = useState(false);

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  function Send() {
    setShowSendButton(false);
    setMessage(
      "Sending Request! Please Wait As This May Take A Couple Of Minutes!"
    );
    if (site === "" || site === "Select your site" || date === "" || name === "" || machine === "" || serialNumber === "" || issue === "" || description === "" ) {
      setTimeout(() => {
        setCompletionAlert(true)
      }, 1000)
      setShowSendButton(true)
      setMessage("")
    }
    else if (image === "") {
      var data = {
        site: site,
        date: date,
        staffName: name,
        machineNumber: machine,
        serialNumber: serialNumber,
        machineMake: make,
        issue: issue,
        description: description,
        // machineModel: model,
        picture: image,
        logged: check,
        logDate: logDate,
        logComment: logComment,
      };
      // console.log(image);
      axios
        .post(
          "https://nitropos-backend.nitrosolutions.co.uk/maintenance/new",
          data
        )
        .then((res) => {
          alert("Request sent");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      fileReader.onload = () => {
        var data = {
          site: site,
          date: date,
          staffName: name,
          machineNumber: machine,
          serialNumber: serialNumber,
          machineMake: make,
          issue: issue,
          description: description,
          // machineModel: model,
          picture: fileReader.result,
          logged: check,
          logDate: logDate,
          logComment: logComment,
        };

        console.log(image);
        axios
          .post(
            "https://nitropos-backend.nitrosolutions.co.uk/maintenance/new",
            data
          )
          .then((res) => {
            alert("Request sent");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      };
    }
  }

  return (
    <section className="form">
      {completionAlert ? (
        <Alert variant="filled" severity="warning" sx={{ width: "90vw", margin: "auto", position: "absolute", top: "90vh" }}>
          All fields must be filled, please try again.
        </Alert>
      ) : (
        <></>
      )}
      <select onChange={(e) => setSite(e.target.value)} required>
        <option>Select your site</option>
        {sites.map(function (d, i) {
          return <option>{d.name}</option>;
        })}
        <option>Crow Rd</option>
      </select>
      <label>
        Date<span>*</span>
      </label>
      <input type="date" onChange={(e) => setDate(e.target.value)} />
      <label>
        Your name<span>*</span>
      </label>
      <input type="text" onChange={(e) => setName(e.target.value)} required />
      <label>
        Machine Type<span>*</span>
      </label>
      <input
        type="text"
        onChange={(e) => setMachine(e.target.value)}
        required
      />
      <label>
        Serial Number<span>*</span>
      </label>
      <input
        type="text"
        onChange={(e) => setSerialNumber(e.target.value)}
        required
      />
      <label>
        Machine Make<span>*</span>
      </label>
      <input type="text" onChange={(e) => setMake(e.target.value)} required />
      {/* <label>
        Machine Model<span>*</span>
      </label>
      <input type="text" onChange={(e) => setModel(e.target.value)} required /> */}
      <select onChange={(e) => setIssue(e.target.value)} required>
        <option>What's the issue</option>
        <option>Fault</option>
        <option>Damaged</option>
      </select>
      <label>
        Description<span>*</span>
      </label>
      <textarea
        type="text"
        onChange={(e) => setDescription(e.target.value)}
        cols="40"
        rows="5"
        required
      />
      <br />
      <div
        style={{
          border: "1px solid #be1a1a",
          borderRadius: "10px",
          padding: "10px",
          width: "375px",
        }}
      >
        <label>Click Here If You Have Logged This Issue</label>
        <input
          type="checkbox"
          value={check}
          onChange={(e) => {
            setCheck(!check);
            console.log(check);
          }}
        />
        {check ? (
          <div>
            <hr />
            <p>Date &amp; Time Logged</p>
            <input style={{width:"100%"}}
              type="datetime-local"
              required
              value={logDate}
              onChange={(e) => setLogDate(e.target.value)}
            /><br/>
            <p>Comment<span>*</span></p>
            <textarea style={{width:"100%"}}
              type="text"
              onChange={(e) => setLogComment(e.target.value)}
              cols="40"
              rows="5"
              required
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <br />
      <label>Upload Any Images (optional)</label>
      <input
        type="file"
        accepts="image/*"
        onChange={(e) => setImage(e.target.files[0])}
        style={{ border: "1px solid #be1a1a" }}
      />
      <br />

      {showSendButton ? (
        <Button
          variant="outlined"
          color="error"
          onClick={() => Send()}
          className="button"
        >
          Send
        </Button>
      ) : (
        <h1 style={{ textAlign: "center", border: "3px solid red" }}>
          {message}
        </h1>
      )}
    </section>
  );
}
