import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/admin/login/Login";
import Dashboard from './components/admin/Dashboard';

function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
