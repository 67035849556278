import "./login.scss";
import logo from "../../../Images/rt-logo.webp";
import { useState, useContext } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import userContext from "../../context/userContext";

export default function Login() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const [form, setForm] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newPassCheck, setNewPassCheck] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [matchAlert, setMatchAlert] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const { setUserData } = useContext(userContext);
  const [success, setSuccess] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    // const regex = /^[A-Za-z]+\.[A-Za-z]+$/;

    if ((username != "") && (password != "")) {
      try {
        const loginUser = { username, password };
        const loginRes = await axios.post(
            "https://nitropos-backend.nitrosolutions.co.uk/maintenance/login",
          loginUser
        );
        sessionStorage.setItem("auth-token", loginRes.data.token);
        sessionStorage.setItem("name", loginRes.data.user.name);
        navigate("/dashboard");
      } catch (err) {
        err.response.data.msg && setMessage(err.response.data.msg);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2500);
      }
    } else {
      setMessage("Username or password is wrong, please try again");
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  };

  function handlePass() {
    if (form) {
      setForm(false);
    } else {
      setForm(true);
    }
  }

  async function passChange(e) {
    e.preventDefault();

    const regex = /^[A-Za-z]+\.[A-Za-z]+$/;

    if (username.match(regex) && newPassword === newPassCheck) {
      try {
        const changePass = { username, newPassword };
        const passRes = await axios.post(
          "https://nitropos-backend.nitrosolutions.co.uk/forgotPass",
          changePass
        );
        setMessage(passRes.data.msg);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2500);
        handlePass();
      } catch (err) {
        err.response.data.msg && setMessage(err.response.data.msg);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 2500);
      }
    } else {
      setMessage(
        "Username is wrong, or passwords don't match. Please try again"
      );
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 2500);
    }
  }

  return (
    <main className="login">
      <img src={logo} alt="logo" />
      {error ? (
        <Alert
          severity="error"
          sx={{
            width: "80vw",
            margin: "auto",
            marginBottom: "2%",
            marginTop: "2%",
          }}
        >
          {message}
        </Alert>
      ) : (
        <></>
      )}
      {success ? (
        <Alert
          severity="success"
          sx={{
            width: "80vw",
            margin: "auto",
            marginBottom: "2%",
            marginTop: "2%",
          }}
        >
          {message}
        </Alert>
      ) : (
        <></>
      )}
      {form ? (
        <>
          <h1>Admin Please login</h1>

          <form onSubmit={submit}>
            <input
              type="text"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <br />
            <input
              type={passwordType}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <input type="submit" value="Login" />
          </form>
        </>
      ) : (
        <>
          <h1>Change password</h1>
          <form onSubmit={passChange}>
            <input
              type="text"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <br />
            <input
              type={passwordType}
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <br />{" "}
            <input
              type={passwordType}
              placeholder="Re-Type Password"
              onChange={(e) => setNewPassCheck(e.target.value)}
            />
            <br />
            <input type="submit" value="Change Password" />
          </form>
        </>
      )}
      <button class="pass" onClick={() => handlePass()}>
        {!form ? <p>Login</p> : <p> Forgot Password</p>}
      </button>
    </main>
  );
}
