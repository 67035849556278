import { useState, useEffect } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import History from "./History";
import Details from "./Details";
import axios from "axios";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Laundry from "../Laundry";
import RepairForm from '../RepairForm'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "70vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};

const styleForm = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "90vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "1rem",
};

export default function Dashboard() {
  const [user, setUser] = useState("");
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [repair, setRepair] = useState();
  const [showRepairs, setShowRepairs] = useState(true)
  const [showLaundry, setShowLaundry] = useState(false)
  const [repairTab, setRepairTab] = useState("#021859")
  const [laundryTab, setLaundryTab] = useState("grey")
  const [openForm, setOpenForm] = useState(false)

  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [supplierList, setSupplierList] = useState([]);

  useEffect(() => {
    const name = sessionStorage.getItem("name");
    const savedToken = sessionStorage.getItem("auth-token");
    setUser(name);
    if (savedToken && name) {
      setAllowed(true);
    }

    axios
      .get("https://nitropos-backend.nitrosolutions.co.uk/maintenance/")
      // .get("http://localhost:4022/maintenance/")  

      .then((res) => {
        setData(res.data);
        let suppliers = [];
        for (let i = 0; i < res.data.length; i++) {
          var index = suppliers.findIndex(
            (d) => d.name == res.data[i].machineMake
          );
          console.log(res.data[i].machineMake, index);
          if (index < 0) {
            suppliers.push({ name: res.data[i].machineMake, occurrence: 1 });
          } else {
            suppliers[index].occurrence = suppliers[index].occurrence + 1;
          }
        }

        console.log(suppliers)
        setSupplierList(suppliers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  const handleRepair = (id) => {
    setRepair(id);
    // console.log(id)
  };

  const changeTab = () => {
    if (showRepairs) {
      setShowRepairs(false)
      setShowLaundry(true)
      setRepairTab("grey")
      setLaundryTab("#021859")
    } else {
      setShowRepairs(true)
      setShowLaundry(false)
      setRepairTab("#021859")
      setLaundryTab("grey")
    }
  }

  return (
    <>
      {allowed ? (
        <section className="admin__dashboard">
          <Header user={user} /><br />
          <div style={{ position: "absolute", top: "10%", left: "2.5%" }}>
            <button style={{ backgroundColor: repairTab, color: "white", borderRadius: "5px", padding: "10px" }} onClick={() => { changeTab() }}>REPAIRS</button>
            <button style={{ backgroundColor: laundryTab, color: "white", borderRadius: "5px", padding: "10px" }} onClick={() => { changeTab() }}>LAUNDRY</button>
          </div>
          <main className="dash__main">
            {showRepairs ?
              <>
                <History data={data} handleRepair={handleRepair} />
                <Details repair={repair} />
              </>
              :
              <></>}
          </main>
          {showRepairs ?
            <>
              <div className="repairs__supplier">
                <AddCircleIcon
                  sx={{ color: "#021859", fontSize: 50 }}
                  onClick={handleOpenForm}
                />
                <BuildCircleIcon
                  sx={{ color: "#BF1a1a", fontSize: 50 }}
                  onClick={handleOpen}
                />
              </div>
              <Modal
                open={openForm}
                onClose={handleCloseForm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleForm}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h3 style={{ textAlign: "center" }}>Add New Repair</h3>
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className="modal__content">
                      <RepairForm />
                    </div>
                  </Typography>
                </Box>
              </Modal>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h3 style={{ textAlign: "center" }}>Repair By Supplier</h3>
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className="modal__content">
                      {supplierList.map(function (d, i) {
                        return (
                          <div className="modal__card">
                            <h4>{d.name}</h4>
                            <h5>{d.occurrence}</h5>
                          </div>);
                      })}
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </>
            : <div style={{ position: "absolute", top: "15%", width: "90%" }}>
                <Laundry />
              </div>}
        </section>
      ) : (
        <>{navigate("/")}</>
      )}
    </>
  );
}
