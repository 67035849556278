import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from "@mui/icons-material/Search";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import axios from "axios";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95vw",
    height: "90vh",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    borderRadius: "1rem",
};

export default function Completed(props) {

    const [open, setOpen] = useState(props.show);
    const [showResults, setShowResults] = useState(false);
    const [searchRepair, setSearchRepair] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [alert, setAlert] = useState(false);
    const [data, setData] = useState([])
    const [repair, setRepair] = useState({})

    useEffect(() => {
        setOpen(props.show)
        axios.get("https://nitropos-backend.nitrosolutions.co.uk/maintenance/complete").then((res) => {
            setData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }, [props, repair])

    const repairDetails = (id) => {
        var rep = data.filter((d) => d._id === id)
        setRepair(rep[0])
        console.log(rep[0])
    }

    function Search() {
        axios
            .get(
                `https://nitropos-backend.nitrosolutions.co.uk/maintenance/search/complete?search=${searchRepair}`
            )
            .then((res) => {
                console.log(res.data.length)
                setSearchData(res.data);
                if (res.data.length != 0) {
                    setShowResults(true);
                } else {
                    setAlert(true);
                    setTimeout(() => {
                        setAlert(false);
                    }, 2500);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            Search()
        }
    }

    return (
        <>
            <Modal
                open={open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h3 style={{ textAlign: "center" }}>COMPLETED REPAIRS</h3>
                    </Typography>
                    <div className="dash__main">
                        <div className="dash__history" style={{ float: "left" }}>
                            <div className="dash__open">
                                <div className="dash__search">
                                    <input
                                        type="text"
                                        className="dash__search"
                                        style={{ width: "100%" }}
                                        placeholder="Search Completed Repair..."
                                        onChange={(e) => setSearchRepair(e.target.value)}
                                        focus
                                        onKeyDown={handleKeyDown}
                                    />
                                    <IconButton onClick={() => Search()}>
                                        <SearchIcon fontSize="large" sx={{ color: "#03318C" }} />
                                    </IconButton>
                                    <IconButton onClick={() => setShowResults(false)}>
                                        <CloseIcon fontSize="medium" sx={{ color: "#03318C" }} />
                                    </IconButton>
                                </div>
                                {alert ? (
                                    <Alert
                                        severity="warning"
                                        sx={{ position: "absolute", top: "13vh", left: "4vw", width: "25vw" }}
                                    >
                                        No matching Repairs
                                    </Alert>
                                ) : (
                                    <></>
                                )}
                                {showResults ? (
                                    <div className="repair">
                                        <hr className="sep" />
                                        <table style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Site</th>
                                                    <th>Date</th>
                                                    <th>Machine Make</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchData.map(function (d, i) {
                                                    return (
                                                        <tr onClick={() => repairDetails(d._id)}>
                                                            <th>{d.site}</th>
                                                            <th>{d.date}</th>
                                                            <th>{d.machineMake}</th>
                                                        </tr>)
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                <hr className="sep" />
                                {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}> */}
                                <table className="repair">
                                    <thead>
                                        <tr>
                                            <th>Site</th>
                                            <th>Date</th>
                                            <th>Machine Make</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data ? data.map(function (d, i) {
                                            return (
                                                <tr key={i} onClick={() => { repairDetails(d._id) }}>
                                                    <th>{d.site}</th>
                                                    <th>{d.date}</th>
                                                    <th>{d.machineMake}</th>
                                                </tr>
                                            )
                                        }) : <></>}
                                    </tbody>
                                </table>
                                {/* </Typography> */}
                            </div>
                        </div>
                        {repair._id ?
                            <div>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <section className="dash__details" style={{ position: "absolute", top: "13%", width: "64%", right: "1.5%" }}>
                                        <div className="status__updates">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Repair ID:</th>
                                                        <th>Submited on:</th>
                                                        <th>Last Updated on:</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>{repair.issueID}</th>
                                                        <th>
                                                            {new Date(repair.submissionDate).toLocaleDateString()}
                                                        </th>
                                                        <th>{new Date(repair.lastUpdate).toLocaleDateString()}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="reprair__cards">
                                            <div className="repair__column">
                                                <div className="card" style={{ height: "30vh", marginRight: "15px" }}>
                                                    <h3>Issue: </h3>
                                                    <p>
                                                        Issue Type: <span>{repair.issue}</span>
                                                    </p>
                                                    <p>
                                                        Machine Make: <span>{repair.machineMake}</span>
                                                    </p>
                                                    <p>
                                                        Description: <span>{repair.description}</span>
                                                    </p>
                                                    <p>
                                                        Serial Number: <span>{repair.serialNumber}</span>
                                                    </p>
                                                </div>
                                                <div className="card" style={{ height: "30vh", marginRight: "15px" }}>
                                                    <h3>Audit Log</h3>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Status</th>
                                                                <th>Update Date</th>
                                                                <th>Staff Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {repair.statusLog.map(function (d, i) {
                                                                return (
                                                                    <tr>
                                                                        <th>{d.newStatus}</th>
                                                                        <th>{new Date(d.updateDate).toLocaleString()}</th>
                                                                        <th>{d.updatedBy}</th>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="audit__log">
                                                <h3>Notes:</h3>
                                                {repair.notes ? (
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Staff</th>
                                                                <th>Note</th>
                                                                <th>Date</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {repair.notes.map(function (d, i) {
                                                                return (
                                                                    <tr>
                                                                        <th>{d.addedBy}</th>
                                                                        <th>{d.note}</th>
                                                                        <th>{new Date(d.addedDate).toLocaleString()}</th>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                ) : (
                                                    <p>No Notes to Show</p>
                                                )}
                                            </div>
                                        </div>
                                    </section>
                                </Typography>
                            </div> :
                            <div>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    <section className="dash__details" style={{ position: "absolute", top: "13%", width: "64%", right: "1.5%" }}>
                                        <div className="details__id">
                                            <h4>Repair ID:</h4>
                                            <h4>----</h4>
                                        </div>
                                        <div className="status__updates">
                                            <table className="status">
                                                <thead>
                                                    <tr>
                                                        <th>Submited on:</th>
                                                        <th>Last Updated on:</th>
                                                    </tr>
                                                </thead>
                                                <tr>
                                                    <th>----</th>
                                                    <th>----</th>
                                                </tr>
                                            </table>
                                        </div>
                                    </section>
                                </Typography>
                            </div>
                        }
                    </div>
                </Box>
            </Modal>
        </>
    )

}